import { Component } from "react";
import "./toast.scss"

class Toast extends Component {
    constructor() {
        super();
        this.confirmTrigger = this.confirmTrigger.bind(this)
    }

    confirmTrigger() {
        this.props.onConfirm();
    }

    render() {
        if (!this.props.visible) return null;

        return (
            <div className="toast-modal">
                <div className="toast-modal-wrapper">
                    {this.props.title ? <p className="toast-modal-title">{this.props.title}</p> : null}
                    {this.props.content ? <p className="toast-modal-content">{this.props.content}</p> : null}
                    {
                        this.props.showConfirmBtn ?
                            <p className="toast-modal-btns">
                                <button className="toast-modal-btns__confirm" onClick={this.confirmTrigger}>{this.props.confirmBtnText || "确定"}</button>
                            </p> : null
                    }
                </div>
            </div>
        )
    }
}

export default Toast