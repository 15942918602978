import { Component } from "react";
import { QrReader } from "react-qr-reader"
import "./QRScanner.scss"
import Toast from "../../components/toast/toast"

class QRScanner extends Component {
  constructor() {
    super();
    this.state = {
      result: null, // 解析结果
      flag: false, // 扫描解析标记
      showToast: false // 弹窗
    }
    this.handleResult = this.handleResult.bind(this);
    this.reScan = this.reScan.bind(this)
    this.copyResult = this.copyResult.bind(this);
  }

  handleResult(data, error) {
    if (this.state.flag) return;

    console.log(">>>>>>> 正在扫描 >>>>>>")
    if (!!error) { return }

    if (!!data) {
      console.log("解析成功：", data)
      this.setState({
        result: data?.text,
        flag: data?.text ? true : false,
        showToast: true
      })
    }
  }

  reScan() {
    this.setState({
      result: null,
      flag: false,
    })
  }

  copyResult() {
    navigator.clipboard.writeText(this.state.result);
    this.setState({
      showToast: false
    })
  }

  render() {
    return (
      <div className="qr-scanner">
        <div className="qr-scanner-wrapper"></div>
        <QrReader
          scanDelay={1000}
          onResult={this.handleResult.bind(this)}
          videoContainerStyle={{ width: '100vw', height: '100vh', paddingTop: 0 }}
          constraints={{
            facingMode: "enverionment",
            autoGainControl: true,
            aspectRatio: 1,
            sampleSize: {
              max: 240,
              min: 240
            }
          }}
        />
        <div className="scanner-horn">
          <span className="scanner-horn-lt"></span>
          <span className="scanner-horn-rt"></span>
          <span className="scanner-horn-lb"></span>
          <span className="scanner-horn-rb"></span>
          <span className="scanner-horn-line"></span>
        </div>
        {this.state.flag ?
          <div className="operate-btn">
            <button className="operate-btn-rescan" onClick={this.reScan}>重新识别</button>
          </div> : null
        }
        <Toast visible={this.state.showToast} title="扫码结果" content={this.state.result} showConfirmBtn={true} confirmBtnText="复制" onConfirm={this.copyResult}></Toast>
      </div>
    )
  }
}

export default QRScanner;
