import './App.css';
import QRScanner from './pages/QRScanner/QRScanner'

function App() {
  return (
    <div className="App">
      <QRScanner />
    </div>
  );
}

export default App;
